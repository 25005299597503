import React from 'react'
import { withPrefix } from 'gatsby'
import siteConfig from '../../../data/siteConfig'
import styled , { css,keyframes  } from 'styled-components'



const HeroContainer = styled.div`
  position: relative;
  display: table;
  width: 100%;
  overflow: hidden;
`

const TitleContainer = styled.div`
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  width: 100%;
`

function randomNum(value) {
  return Math.floor(Math.random() * value) + 1
}

function glitchSkewCss() {
  let styles = '';
  for (let i = 0; i < 10; i += 1) {
    styles += `${Math.round(i*(1/10) * 100)}% {
      transform: skew(${randomNum(10) - 5}deg);
    }
    `
  }
  return keyframes`${styles}`;
}

function glitchAnim2Css() {
  let styles = '';
  for (let i = 0; i < 20; i += 1) {
    styles += `${Math.round(i*(1/20) * 100)}% {
      clip: rect(${randomNum(100)}px, 9999px, ${randomNum(100)}px, 0);
			transform: skew(${(randomNum(100)) / 100}deg);
    }
    `
  }

  return keyframes`${styles}`;
}

function glitchAnimCss() {
  let styles = '';
  for (let i = 0; i < 20; i += 1) {
    styles += `${Math.round(i*(1/20) * 100)}% {
      clip: rect(${randomNum(100)}px, 9999px, ${randomNum(100)}px, 0);
			transform: skew(${(randomNum(100)) / 100}deg);
    }
    `
  }

  return keyframes`${styles}`;
}

const glitchCopy = css`
    color: white;
    content: attr(data-text);
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
`;

const HeroTitle = styled.h1`

  font-size: 3rem;
  margin: 10px 60px;
  color: #fff;
  text-shadow: 1px 1px 4px rgba(34, 34, 34, 0.6);
  font-family: Montserrat;
  position: relative;
	color: white;
  font-family: 'Montserrat', sans-serif;
	letter-spacing: .3em;

	animation: ${glitchSkewCss()} 1s infinite linear alternate-reverse;
	
	&::before{

		${glitchCopy}

		left: 2px;
		text-shadow: -2px 0 #ff00c1;
		clip: rect(44px, 450px, 56px, 0);
		animation: ${glitchAnimCss()} 5s infinite linear alternate-reverse;
	}
	
	&::after {
		${glitchCopy}
		left: -2px;
		text-shadow: -2px 0 #00fff9, 2px 2px #ff00c1;
		animation: ${glitchAnim2Css()} 1s infinite linear alternate-reverse;
	}

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`

const HeroSubTitle = styled.span`
  color: rgb(100,220,220);
  letter-spacing: 1em;
`

const Hero = ({ className, title }) => (
  <HeroContainer className={className}>
    <TitleContainer>
      <HeroTitle data-text={title}>{title}</HeroTitle>
      <HeroSubTitle></HeroSubTitle>
    </TitleContainer>
  </HeroContainer>
)

export default styled(Hero)`
  ${p => `
  
  background: linear-gradient(rgba(10,10,10,.6),rgba(0,0,0,.9)), repeating-linear-gradient(0, transparent, transparent 2px, black 3px, black 3px),
  url(${p.heroImg || withPrefix(siteConfig.siteCover)});` }
  margin: 0;
	padding: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
  height: 70vh;
  background-attachment: fixed;
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: cover;


  
`
