module.exports = {
    siteTitle: 'Hi! I\'m Okasha!',
    siteDescription: `personal curriculum`,
    keyWords: ['okasha', 'cv', 'curriculum', 'software engineer', 'libya'],
    authorName: 'Mohamed Okasha',
    twitterUsername: 'm_3okasha',
    githubUsername: 'mohamed-okasha',
    authorAvatar: '/images/avatar.jpeg',
    authorDescription: `
    Software Engineer, enthusiastic about what I do. Since I was always interested in how things worked under the hood, I began studying Assembly, C/C++, and VB as a hobby in high school.<br />
In the following years, I learned a wide range of programming languages and desktop/web technologies.<br />
I usually play multiple roles in companies, such as project management. Provide guidance on best practices at all stages of the Software Development Lifecycle SDLC, including developing and deploying tools for managing knowledge bases and monitoring servers and systems.<br />
In my current position, I work primarily with Laravel | Spring Boot as backend frameworks, Javascript & Vue as frontend frameworks.`,
    skills: [{
            name: 'JavaScript',
            level: 70
        },
        {
            name: 'Java',
            level: 70
        },
        {
            name: 'PHP',
            level: 75
        },
        {
            name: 'Python',
            level: 77
        },
        {
            name: 'Pandas, Numpy, Scikit-learn ..',
            level: 40
        },
        {
            name: 'Laravel',
            level: 80
        },
        {
            name: 'Django',
            level: 55
        },
        {
            name: 'Flask',
            level: 70
        },
        {
            name: 'Vue.js',
            level: 70
        },
        {
            name: 'React',
            level: 30
        },
        {
            name: 'Google Cloud Platform (GCP)',
            level: 20
        },
        {
            name: 'Amazon Web Service (AWS)',
            level: 40
        },
        {
            name: 'DevOps Ansible, Docker (Python, Bash)',
            level: 15
        },
        {
            name: 'Problem Solving',
            level: 50
        },
        {
            name: 'Software Architect',
            level: 20
        },
        /* more skills here */
    ],
    jobs: [
        /* more jobs here */
        {
            company: "Presto Eat Co.",
            begin: {
                month: 'feb',
                year: '2022'
            },
            duration: null,
            occupation: "Software Engineer",
            description: ` - `
        },
        {
            company: "Libya Guide Information Technology Ltd.",
            begin: {
                month: 'feb',
                year: '2021'
            },
            duration: '1 yr e 2 mos',
            occupation: "Software Engineer",
            description: `● I played a role in getting the company to become PCI-compliant.<br />
      ● Contributed to improving the subscription system in the API of the tracking service provided for hafelati.com. <br />
      ● Contributed to adding OLAP capabilities in GPay System by developing the component that migrates data from Cassandra database to Postgres.`
        },
        {
            company: `
            Arttech Technology `,
            begin: {
                month: 'feb',
                year: '2019'
            },
            duration: '1 yr e 2 mos',
            occupation: `
            Team Lead Engineer `,
            description: `●
            Helped in recruiting and managing a team of six software engineers. < br / > ●Worked a comprehensive eco - system
            for managing e - tickets and NFC prepaid payment cards.The eco - system includes a web and android application to deal with NFC cards used at POS. < br / > ●Developed with the team,
            a loyalty QR Cards Management System(web and mobile). < br / > ●Built a real - time tracking components as part of food delivery system(Speedo).Based Google Maps
            and WebSockets technologies. < br / > `
        },
        {
            company: `
            Masarat For IT & Financial Services `,
            begin: {
                month: 'apr',
                year: '2018'
            },
            duration: null,
            occupation: "R&D Engineer",
            description: `●
            Designed an ML model to predict pharmacy store visits a week prior to the
            customer 's actual visit. This model has been trained on a data set from the
            MobiCash local payment system and has 80 % accuracy on the testset. < br / > ●Created a GitLab CI / CD pipeline that automated the development and
            deployment process on GCP Instance.The pipeline stages include style
            checks,
            dependency and security checks,
            unit testing with coverage
            reports,
            deployment. < br / > ●Attended as a team member that represented the company’ s in a Virtual
            Hackathon Organized by CentralBank of Kenya.Our contribution was
            with an app contact tracing to track potential COVID - 19 exposure.
            `

        },
        {
            company: "Libyan Spider",
            begin: {
                month: 'feb',
                year: '2017'
            },
            duration: '1 yr',
            occupation: "System Engineer",
            description: `●
            Hardware and software configuration
            for servers including mounting components,
            storage raid configuration,
            installing and configuring firewalls,
            validating the server health metrics,
            and monitoring via OpsView,
            Zabbix,
            Graylog. < br / > ●Debugging critical issues that affect a wide range of clients using Apache HTTP Servers,
            Tomcat Servers,
            Exim,
            Postfix and similar tools.
            `

        },
    ],
    portifolio: [{
            image: "https://i.imgur.com/C3FGeEf.png",
            description: "(Private) (Vue, Laravel) A web application to manage and follow up Corona cases, which was specially developed for the National Center for Disease Control in Libya. Being one of the biggest contributors, I developed features like working offline and syncing with the internet once it got back.",
            url: null
        },
        {
            image: "https://i.imgur.com/IUTucln.png",
            description: "(Public) (Vue, Laravel) A web application for Albadeel Alansab For Contracting and Real Estate Investment one of the largest property companies in Libya, one of the unique features was that the site admin can define new properties and their types and then generate forms to fill out and modify them with data, this provides great flexibility",
            url: "https://www.albadeelalansab.com/"
        },
        {
            image: "https://i.imgur.com/TlII9yA.png",
            description: ` (R & D Masarat)(Java, Php) CrossPaths app with the web dashboard is a tool that helps identify and notify people exposed to coronaviruse(COVID - 19) based on GPS location history,
            this app shows infected,
            recovered and dead!statistics and can extended to include another feature such as treatment protocols,
            treatment centers and manage exit permits during curfew on each libyan municipality.We participated with this application and represented the company in a Virtual
            Hackathon Organized by CentralBank of Kenya.
            `,
            url: 'https://drive.google.com/file/d/1fLSgjeAVLnDxreqmghw_3ZH9biiB1mL_/view?usp=sharing'
        },
        {
            image: "/images/alnabeel sweets.png",
            description: ` (Personal)(Python) Marah chatbot was developed
            for Alnabil Sweets one of the largest sweet stores in the middle region of libya,
            The chatbot based on natural language processing techniques.Libraries like Tashaphyne and some tools from Arabeyes team and AI platform(wit.ai from facebook) provide technology to extract entities and predict intent from multi - language queries including arabic.
            The sweet requests are managed through a dashboard developed with Java Server Faces and PrimeFaces components.
            `,
            url: "https://drive.google.com/file/d/11TqnwYLC89qavHmr7YF0Rc8kpQ9w4B64/view?t=94"
        },
        {
            image: "https://github.com/mohamed-okasha/Madinaty/raw/master/ide_preview1.jpg",
            description: ` (Personal)(VB) An interactive WPF application that gives a general overview of zliten city and its historic figures,
            landmarks.
            What is unique about this project is that I have developed its own file format(.dpz) each file containing the media data in the book "Aalam Libya by Sheikh Taher Al-Zawy” organized and can be created through a program that we distributed to all the members of the team`,
            url: null
        },
        {
            image: "/images/arduino bari3.jpg",
            description: `(Side Project) (C++) transpiler (source-to-source compiler) for Ardunio IDE named “Arduino Bari3”. This application
      takes Arabic (programming language like) syntax and converts it to
      Arduino C syntax in the background then compile it. Click to check out in SourceForge`,
            url: "https://sourceforge.net/projects/arduino-bari3/"
        },
        {
            image: "https://github.com/mohamed-okasha/vlc_extenstion.dirty-sexual-skipper/raw/master/preview.png",
            description: `(Side Project) (Lua) extenstion automate skipping dirty/sexual content in VLC media player.`,
            url: "https://github.com/mohamed-okasha/vlc_extenstion.dirty-sexual-skipper"
        },
        {
            image: "https://github.com/mohamed-okasha/carboniter/raw/master/example.png",
            description: `(Side Project) (Node.js) Carboniter slack bot is a scraper tool for carbon.now.sh website`,
            url: "https://github.com/mohamed-okasha/carboniter"
        },
        /* more portifolio items here */
    ],
    achievments: [
        {
            image: "/images/ml.png",
            description: `After completing the Popular Stanford Online Machine Learning course under the supervision of Professor Andrew NG, I was awarded a certificate with a grade of 89.43%,`,
            url: "https://coursera.org/share/b4f8a1da5e5988cf4aacad1284793838"
        },
        {
            image: "/images/fundations of projectmanagement.png",
            description: `With a grade of 92.34%, I earned a certificate for passing the Foundations of Project Management Course offered by Google.
            This course is the first in a series of six to equip you with the skills you need to apply to introductory-level roles in project management. For non-specialists this part is enough to cover the fundations.`,
            url: "https://coursera.org/share/a5b0d125b8e9ca649982e4d3f4898980"
        },
        {
            image: "/images/LibyaSpiderCertificate.jpg",
            description: `The Libyan Spider Company awarded me an experience certificate when I worked as a technical support engineer and then a system engineer`,
            url: "https://drive.google.com/file/d/1mxpIMhNYFnrd9WuPofz-xuDy0zT1_p8x/view"
        },
        {
            image: "/images/Bank_Of_Kenya.PNG",
            description: `I have a certificate of participation in the virtual africa hackathon, our app was
            chosen from among 15 applications that participated in the finalists out of 74
            submissions.`,
            url: "https://drive.google.com/file/d/19Sm9xSg4qsR9l8BYw_yLjgDMP1hVlJrj/view"
        },
        {
            image: "/images/hackathon_raqami.jpg",
            description: `Our solution (Insightly AI) to help small businesses automate and work remotely was selected as the most creative solution at the RAQME-E Hackathon.`,
            url: "/pdf/insightly.pdf"
        },
        {
            image: "/images/cybrary.PNG",
            description: `I have a certificate in Secure Development, Programming, and Coding with Veracode From Cybrary.`,
            url: "/pdf/cybrary-cert-secure-development-programming-coding.pdf"
        },
        /* more achievments items here */
    ],
    social: {
        twitter: "https://twitter.com/m_3okasha",
        linkedin: "https://www.linkedin.com/in/mohamed3okasha",
        github: "https://github.com/mohamed-okasha",
        stackoverflow: "https://stackoverflow.com/users/2350544/mohamed-okasha",
        blogger: "https://mohamed3okasha.blogspot.com/",
        podcast: "https://podcasts.apple.com/us/podcast/%D9%83%D8%B1%D9%88%D8%B3%D9%83%D8%A7%D9%84/id1497151818",
        email: "mohammed.okasha.ps@gmail.com",
    },
    siteUrl: 'https://mohamed3okasha.blogspot.com/',
    pathPrefix: '/gatsby-starter-cv', // Note: it must *not* have a trailing slash.
    siteCover: '/images/cover2.png',
    googleAnalyticsId: 'UA-000000000-1',
    background_color: '#ffffff',
    theme_color: '#25303B',
    fontColor: "#000000cc",
    enableDarkmode: true, // If true, enables dark mode switch
    display: 'minimal-ui',
    icon: 'src/assets/gatsby-icon.png',
    headerLinks: [{
            label: 'Okasha',
            url: '/',
        },
        {
            label: 'Portifolio',
            url: '/portifolio',
        },
        {
            label: 'Achievements',
            url: '/achievements',
        }
    ]
}